import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Progress } from 'rsuite';
import LoaderComponent from '../../components/loader-component';
import InvalidHandoffExpiredLink from '../../components/invalid-handoff-expired-link';
import ConfirmYourIdentity from './confirm-your-identity';
import { getPingInfoAPI, getPingProfileInfoAPI, updatePartialPatientPingAPI } from '../../api/patient-ping/PatientPingAPI';
import { PatientPing } from './interfaces';
import { formatPhoneNumber, PATIENT_PING_INITIAL_DATA, PING_STEP_NAMES, PingScanRequest, transformPingData } from './utils';
import AdSection from '../../components/ad-show';
import PatientPingInfo from './patinet-pint-info';
import HeightAndWeight from '../../components/height-and-weight';
import Roi from '../../components/roi';
import SelectAppointmentDatetimes from '../../components/select-appointment-datetimes';
import { PATIENT_PING_SOURCE } from '../../utils/GeneralUtil';
import PaymentMethodComponent from '../../components/payment-method-component';
import PatientPingSubmitWrapper from './patient-ping-submit-wrapper';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { STEP_COMPLETED } from '../../utils/MixpanelEvents';
import CovidQuestions from '../../components/covid-questions';
import PatientLocation from '../../components/patient-location';
import PurchaseInformation from '../../components/purchase-information';
import CCTAPaymentCMethodComponent from '../../components/payment-ccta-method';
import HowToCoverCCTAAppointment from '../../components/how-to-cover-ccta-appointment';
import { CLEERLY_HANDOFF_STEP_NAMES } from '../handoff-process/cleerly/utils';
import MammogramDatetimes from '../../components/mammogram';
import UploadPrescription from '../../components/upload-prescription';
import { Trans, useTranslation } from 'react-i18next';
import MetalBodyWrapper from '../../components/metal-body-wrapper';
import GeneralQuestion from '../../components/general-question';
import ContrastComponent from '../../components/contrast-component';
import GeneralError from '../../components/general-error';
import useLocationLanguage from '../../hooks/useLocationLanguage';
import Availability from '../../components/availability';
import MammogramBreastDatetimes from '../../components/mammogram-breast';
import MammogramBreastSymptoms from '../../components/mammogram-breast-symptoms';
import PreferredEmail from '../../components/email';
import SchedulingDetails from '../../components/appointment-details';
import MetalInBodyWrapper from '../../components/metal-in-body-wrapper';
import AssistanceWrapper from '../../components/assistance';
import DiabetesWrapper from '../../components/diabetes';
import PacemakerWrapper from '../../components/pacemaker-wrapper';
import ClaustrophobiaWrapper from '../../components/claustrophobia';
import Allergies from '../../components/allergies';
import SurgeryWrapper from '../../components/surgery';
import Medications from '../../components/medications';
import PulmonaryConditions from '../../components/pulmonary';
import PhysicalSymptoms from '../../components/physical-symptoms';
import ChemotherapyWrapper from '../../components/chemotherapy';
import CancerHistoryWrapper from '../../components/cancer-history';
import SmokingHabitsWrapper from '../../components/smoking-habits';

const PatientPingProcess: React.FunctionComponent = () => {
  const { Line } = Progress;
  const lang = useLocationLanguage();
  const { invitationID } = useParams<{ invitationID: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [invitationHasError, setInvitationHasError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modulesData, setModulesData] = useState({});
  const [scanData, setScanData] = useState(PATIENT_PING_INITIAL_DATA);
  const [patientPingData, setPatientPingData] = useState<PatientPing | null>(null);
  const [purchaseAddInfo, setPurchaseAddInfo] = useState({ isCardErrorMessageShown: false });
  const mixpanel = useContext(MixpanelContext);
  const { t } = useTranslation('handoff');

  const percentage = useMemo(() => {
    const steps = patientPingData ? patientPingData.ModuleCount + 1 : 1;
    return (scanData.currentStep / steps) * 100;
  }, [scanData.currentStep, patientPingData]);

  const agentInfo = useMemo(() => {
    return {
      CxName: patientPingData?.AgentName || '[CX First Name]',
      CxPhone: patientPingData?.AgentPhone || '[CX phone]',
      CxEmail: patientPingData?.AgentEmail || '[CX email]',
    };
  }, [patientPingData]);

  const infoBoxData = useMemo(() => {
    return {
      title: t('box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.title', { ns: 'translations' }),
      content: (
        <Trans
          i18nKey='box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.content'
          values={{
            CxName: patientPingData?.AgentName || '[CX First Name]',
            CxPhone: patientPingData?.AgentPhone || '[CX phone]',
            CxEmail: patientPingData?.AgentEmail || '[CX email]',
          }}
          components={{ linkTo: <a /> }}
        />
      ),
    };
  }, [patientPingData]);

  useEffect(() => {
    getPatientPingInfo();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [scanData.currentStep]);

  const getPatientPingInfo = (): void => {
    getPingInfoAPI(invitationID)
      .then(response => {
        const agentPhone = response.AgentPhone ? formatPhoneNumber(response.AgentPhone) : response.AgentPhone;
        const data = { ...response, AgentPhone: agentPhone };
        setPatientPingData(data);
      })
      .catch(() => {
        setInvitationHasError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const getPatientPingProfileInfo = async (): Promise<void> => {
    const result = await getPingProfileInfoAPI(invitationID);
    const newData = transformPingData(result);
    setScanData(({ currentStep, ...rest }) => {
      return {
        ...rest,
        currentStep,
        scanRequestData: { ...rest.scanRequestData, ...newData },
      };
    });
  };

  const submitPartialData = async (submitData: any) => {
    const payload = { [submitData.ModuleID]: submitData };
    await updatePartialPatientPingAPI(invitationID, payload, lang)
      .then(() => {
        setIsLoading(false);
      })
      .catch(error => {
        const message = error.message;
        if (error.status && error.status === 400 && message && message.toLowerCase().indexOf('payment message') > -1) {
          return setIsLoading(false);
        }
        setIsError(true);
      })
      .finally(() => isLoading && setIsLoading(false));
  };

  const goToPreviousStep = useCallback(
    (stepName?: string): void => {
      if (isError) {
        setIsError(false);
      }
      setScanData(({ currentStep, ...rest }) => {
        const currentMiddleStep = currentStep.toString().match(/\.(\d+)/)?.[1];
        let prevStep = currentStep - 1;
        if (currentStep === 1) {
          prevStep = 0.75;
        } else if (
          CLEERLY_HANDOFF_STEP_NAMES.paymentInformation === stepName ||
          CLEERLY_HANDOFF_STEP_NAMES.insuranceInformation === stepName
        ) {
          prevStep = currentStep - 0.25;
        } else if (currentMiddleStep && CLEERLY_HANDOFF_STEP_NAMES.purchaseInformation === stepName) {
          prevStep = currentStep - 0.25;
        }
        return {
          ...rest,
          currentStep: prevStep,
          errorSubmitting: false,
        };
      });
    },
    [patientPingData, isError],
  );

  const goToCustomPreviousStep = useCallback(
    (stepName?: string, error?: string): void => {
      if (error && error.toLowerCase().indexOf('payment message') > -1 && patientPingData) {
        let isCCTACleerly = false;
        const isCleerlyModuleIndex = patientPingData?.ModuleList.findIndex(el => {
          if (el.ModuleID === 12 || el.ModuleID === 13) {
            isCCTACleerly = el.ModuleID === 12;
            return true;
          }

          return false;
        });
        if (isCleerlyModuleIndex > -1) {
          const purchaseError = Object.assign({ ...purchaseAddInfo, isCardErrorMessageShown: true });
          setPurchaseAddInfo(purchaseError);
          let nextStep = isCleerlyModuleIndex + 1;
          if (isCCTACleerly) {
            nextStep += 0.5;
          }
          setScanData(({ ...rest }) => {
            return {
              ...rest,
              currentStep: nextStep,
            };
          });
        }
      } else {
        goToPreviousStep(stepName);
      }
    },
    [scanData, patientPingData],
  );

  const getPatientPingAddInfo = () => {
    return {
      title: 'insurance.Please provide images of your insurance card',
      uploadCardText: 'insurance.Upload your insurance card to speed up the booking process',
      isOnlyUploadEnabled: true,
    };
  };

  const getDynamicTemplateForStep = () => {
    const currentMiddleStep = scanData.currentStep.toString().match(/\.(\d+)/)?.[1];
    let currentModule = patientPingData?.ModuleList[scanData.currentStep - 1];
    if (currentMiddleStep && !currentModule) {
      const step = Number(scanData.currentStep.toString().split('.')[0]);
      currentModule = patientPingData?.ModuleList[step - 1];
    }

    if (!currentModule || !patientPingData) {
      return null;
    }
    const { ScanCost, MinPrice, Fee, TotalPrice } = patientPingData;
    switch (currentModule.ModuleID) {
      case 1:
        return (
          <PatientLocation
            source={PATIENT_PING_SOURCE}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 3:
        return (
          <PaymentMethodComponent
            goToPreviousStep={goToPreviousStep}
            scanRequestData={{
              ...scanData.scanRequestData,
              SelfPay: 1,
              scanServiceSelected: { MinPrice, Fee, TotalPrice, ScanTypeName: '', ScanServiceID: 0 },
            }}
            completeStepCallback={completeStepCallback}
            source={PATIENT_PING_SOURCE}
          />
        );
      case 4:
        return (
          <PaymentMethodComponent
            goToPreviousStep={goToPreviousStep}
            scanRequestData={{ ...scanData.scanRequestData, SelfPay: 0 }}
            completeStepCallback={completeStepCallback}
            source={PATIENT_PING_SOURCE}
          />
        );
      case 5:
        return (
          <PaymentMethodComponent
            goToPreviousStep={goToPreviousStep}
            scanRequestData={{ ...scanData.scanRequestData, SelfPay: 0 }}
            completeStepCallback={completeStepCallback}
            source={PATIENT_PING_SOURCE}
            addInfo={getPatientPingAddInfo()}
          />
        );
      case 6:
        return (
          <SelectAppointmentDatetimes
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
            moduleData={currentModule}
          />
        );
      case 7:
        return (
          <SelectAppointmentDatetimes
            goToPreviousStep={goToPreviousStep}
            isFlexibleShown={false}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
            moduleData={currentModule}
          />
        );
      case 8:
        return (
          <Roi
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={scanData.scanRequestData}
            stepName={PING_STEP_NAMES.roiConsent}
            agentInfo={agentInfo}
          />
        );
      case 9:
        return (
          <HeightAndWeight
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={scanData.scanRequestData}
            agentInfo={agentInfo}
          />
        );
      case 10:
        return (
          <CovidQuestions
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={scanData.scanRequestData}
            stepName={PING_STEP_NAMES.covid}
            agentInfo={agentInfo}
          />
        );
      case 11:
        return (
          <MetalBodyWrapper
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={scanData.scanRequestData}
          />
        );
      case 12:
        if (currentMiddleStep) {
          if (currentMiddleStep === '25') {
            return (
              <CCTAPaymentCMethodComponent
                goToPreviousStep={goToPreviousStep}
                scanRequestData={scanData.scanRequestData}
                completeStepCallback={completeStepCallback}
                source={PATIENT_PING_SOURCE}
              />
            );
          } else if (currentMiddleStep === '5') {
            if (ScanCost && Number(ScanCost) <= 0) {
              const metaData = {
                PaymentID: '',
                PaymentSecret: '',
                CardType: '',
                CardLast4: 0,
              };

              completeStepCallback(metaData, CLEERLY_HANDOFF_STEP_NAMES.purchaseInformation);

              return <LoaderComponent className='loader-block-center' />;
            }
            return (
              <PurchaseInformation
                patientData={{ FirstName: patientPingData.PatientFirstName, LastName: patientPingData.PatientLastName }}
                goToPreviousStep={goToPreviousStep}
                scanRequestData={{ ...scanData.scanRequestData, ScanCost }}
                completeStepCallback={completeStepCallback}
                additionalInfo={purchaseAddInfo}
              />
            );
          }
        }
        return (
          <HowToCoverCCTAAppointment
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 13: {
        if (ScanCost && Number(ScanCost) <= 0) {
          const metaData = {
            PaymentID: '',
            PaymentSecret: '',
            CardType: '',
            CardLast4: 0,
          };

          completeStepCallback(metaData, CLEERLY_HANDOFF_STEP_NAMES.purchaseInformation);

          return <LoaderComponent className='loader-block-center' />;
        }

        return (
          <PurchaseInformation
            patientData={{ FirstName: patientPingData.PatientFirstName, LastName: patientPingData.PatientLastName }}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={{ ...scanData.scanRequestData, ScanCost }}
            completeStepCallback={completeStepCallback}
            additionalInfo={purchaseAddInfo}
          />
        );
      }
      case 14:
        return (
          <MammogramDatetimes
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 15:
        return (
          <UploadPrescription
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 16: {
        const Question = currentModule.ModuleMetadata?.CustomNote || '';
        return (
          <GeneralQuestion
            question={Question}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      }
      case 17: {
        return (
          <ContrastComponent
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      }
      case 18:
        return (
          <PatientLocation
            source={PATIENT_PING_SOURCE}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 19: {
        return (
          <PreferredEmail
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
            agentInfo={agentInfo}
          />
        );
      }
      case 20: {
        return (
          <Availability
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
            moduleData={currentModule}
          />
        );
      }
      case 21: {
        return (
          <SchedulingDetails
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
            agentInfo={agentInfo}
          />
        );
      }
      case 22:
        return (
          <CovidQuestions
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={scanData.scanRequestData}
            stepName={PING_STEP_NAMES.covid}
            agentInfo={agentInfo}
          />
        );
      case 23:
        return (
          <AssistanceWrapper
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 24:
        return (
          <MammogramBreastDatetimes
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 25:
        return (
          <MammogramBreastSymptoms
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 26:
        return (
          <DiabetesWrapper
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 27: {
        return (
          <Allergies
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      }
      case 28:
        return (
          <Medications
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            stepName={PING_STEP_NAMES.covid}
            scanRequestData={scanData.scanRequestData}
            agentInfo={agentInfo}
          />
        );
      case 29: {
        return (
          <SurgeryWrapper
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      }
      case 30:
        return (
          <MetalInBodyWrapper
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={scanData.scanRequestData}
          />
        );
      case 31:
        return (
          <PacemakerWrapper
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={scanData.scanRequestData}
          />
        );
      case 32:
        return (
          <ClaustrophobiaWrapper
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={scanData.scanRequestData}
          />
        );
      case 33: {
        return (
          <ChemotherapyWrapper
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      }
      case 34: {
        return (
          <CancerHistoryWrapper
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      }
      case 35: {
        return (
          <PulmonaryConditions
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      }
      case 36: {
        return (
          <PhysicalSymptoms
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      }
      case 37: {
        return (
          <SmokingHabitsWrapper
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      }
      default:
        return null;
    }
  };

  const completeStepCallback = useCallback(
    async (stepMetadata?: Partial<PingScanRequest>, stepName?: string) => {
      const stepDataToSave = stepMetadata || {};
      const currentModule = patientPingData?.ModuleList[scanData.currentStep - 1];
      const currentMiddleStep = scanData.currentStep.toString().match(/\.(\d+)/)?.[1];
      if (scanData.currentStep >= 1) {
        if (currentModule) {
          const isOneModule = patientPingData.ModuleList.length === 1;
          const lastModuleID = patientPingData.ModuleList[patientPingData.ModuleList.length - 1].ModuleID;
          if (!isOneModule && lastModuleID !== currentModule.ModuleID && stepDataToSave && Object.keys(stepDataToSave).length) {
            let newStepDataToSave = { ...stepMetadata };
            if (currentModule.ModuleID === 4 || currentModule.ModuleID === 5) {
              const { BackOfInsuranceCardFileKey, FrontOfInsuranceCardFileKey } = scanData.scanRequestData;
              const NewBackOfInsuranceCardFileKey = newStepDataToSave.BackOfInsuranceCardFileKey;
              const NewFrontOfInsuranceCardFileKey = newStepDataToSave.FrontOfInsuranceCardFileKey;
              if (BackOfInsuranceCardFileKey === NewBackOfInsuranceCardFileKey) {
                newStepDataToSave = Object.assign(newStepDataToSave, { BackOfInsuranceCardFileKey: null });
              }
              if (FrontOfInsuranceCardFileKey === NewFrontOfInsuranceCardFileKey) {
                newStepDataToSave = Object.assign(newStepDataToSave, { FrontOfInsuranceCardFileKey: null });
              }
              if (currentModule.ModuleID === 4 || currentModule.ModuleID === 5) {
                await submitPartialData({ ModuleID: currentModule.ModuleID, ...newStepDataToSave });
              }
            } else {
              await submitPartialData({ ModuleID: currentModule.ModuleID, ...newStepDataToSave });
            }
          }
          const dataToSave = Object.keys(stepDataToSave).length
            ? { ModuleID: currentModule.ModuleID, ...stepDataToSave }
            : { ...stepDataToSave };
          setModulesData(prev => ({
            ...prev,
            [currentModule.ModuleID]: dataToSave,
          }));
        } else if (currentMiddleStep) {
          const step = Number(scanData.currentStep.toString().split('.')[0]);
          const currentMiddleModule = patientPingData?.ModuleList[step - 1];
          if (currentMiddleModule) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const prevData = modulesData[currentMiddleModule.ModuleID] ? modulesData[currentMiddleModule.ModuleID] : {};
            setModulesData(prev => ({
              ...prev,
              [currentMiddleModule.ModuleID]: {
                ...prevData,
                MuduleID: currentMiddleModule.ModuleID,
                ...stepDataToSave,
              },
            }));
          }
        }
      }

      if (scanData.currentStep === 0) {
        await getPatientPingProfileInfo();
      }

      if (purchaseAddInfo.isCardErrorMessageShown) {
        const purchaseError = Object.assign({ ...purchaseAddInfo, isCardErrorMessageShown: false });
        setPurchaseAddInfo(purchaseError);
        setScanData(({ currentStep, ...rest }) => {
          const nextStep = patientPingData ? patientPingData.ModuleList.length + 1 : currentStep + 1;
          return {
            ...rest,
            currentStep: nextStep,
            scanRequestData: { ...rest.scanRequestData, ...stepDataToSave },
          };
        });
      } else {
        setScanData(({ currentStep, ...rest }) => {
          const isCurrentMiddleStep = currentStep.toString().match(/\.(\d+)/)?.[1];
          let nextStep = currentStep + 1;
          if (currentStep < 1) {
            // nextStep = currentStep === 0 ? 0.5 : currentStep === 0.5 ? 0.75 : currentStep === 0.75 ? 1 : currentStep + 1;
            nextStep = currentStep === 0 ? 0.75 : currentStep === 0.75 ? 1 : currentStep + 1;
          } else if (
            CLEERLY_HANDOFF_STEP_NAMES.howToCoverAppointment === stepName ||
            CLEERLY_HANDOFF_STEP_NAMES.paymentInformation === stepName ||
            CLEERLY_HANDOFF_STEP_NAMES.insuranceInformation === stepName
          ) {
            nextStep = currentStep + 0.25;
          } else if (isCurrentMiddleStep && CLEERLY_HANDOFF_STEP_NAMES.purchaseInformation === stepName) {
            nextStep = currentStep + 0.5;
          }

          return {
            ...rest,
            currentStep: nextStep,
            scanRequestData: { ...rest.scanRequestData, ...stepDataToSave },
          };
        });
      }

      if (scanData.currentStep >= 1) {
        mixpanel.track(`PATIENT_PING_${STEP_COMPLETED}_${stepName}`, { invitationID, ...stepMetadata });
      }
    },
    [scanData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep } = scanData;
    if (!patientPingData) {
      return null;
    }
    if (currentStep >= 1) {
      const currentModule = patientPingData?.ModuleList[scanData.currentStep - 1];
      const currentMiddleStep = scanData.currentStep.toString().match(/\.(\d+)/)?.[1];
      if (!currentModule && !currentMiddleStep && scanData.currentStep > patientPingData?.ModuleList.length) {
        const lastModuleID = patientPingData.ModuleList[patientPingData.ModuleList.length - 1].ModuleID;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let newModulesData = { [lastModuleID]: modulesData[lastModuleID] };
        if (!newModulesData) {
          newModulesData = modulesData;
        }

        return (
          <PatientPingSubmitWrapper
            patientPingData={patientPingData}
            goToPreviousStep={goToCustomPreviousStep}
            modulesData={newModulesData}
          />
        );
      }
      return getDynamicTemplateForStep();
    }

    switch (currentStep) {
      case 0:
        return <ConfirmYourIdentity patientPingData={patientPingData} completeStepCallback={completeStepCallback} />;
      case 0.5: {
        const patient = {
          GenderName: 'Unknown',
          DOB: scanData.scanRequestData.PatientDOBValidated ? scanData.scanRequestData.PatientDOBValidated : null,
        };
        if (patientPingData.PatientGenderId) {
          patient.GenderName =
            patientPingData.PatientGenderId === 1 ? 'Female' : patientPingData.PatientGenderId === 2 ? 'Male' : 'Unknown';
        }
        return (
          <AdSection
            npi={patientPingData.PrescriberNPI}
            patient={patient}
            infoBoxData={infoBoxData}
            scan={{ CPTCode: patientPingData?.ScanCPTCode }}
            completeStepCallback={completeStepCallback}
          />
        );
      }
      case 0.75:
        return <PatientPingInfo patientPingData={patientPingData} completeStepCallback={completeStepCallback} />;
      default:
        return null;
    }
  }, [scanData, patientPingData, isError]);

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  if (invitationHasError) {
    return <InvalidHandoffExpiredLink />;
  }

  if (isError) {
    return <GeneralError goToPreviousStep={goToPreviousStep} isFromPXB={true} />;
  }

  return (
    <section className='main-request-section'>
      <Line percent={percentage} strokeColor='#ff8a35' />
      <div className='container'>
        <div className='row'>{currentStepDisplayed}</div>
      </div>
    </section>
  );
};

export default PatientPingProcess;
